.App {
  text-align: center;
}

@font-face {
  font-family: 'BioSans';
  src: url('fonts/Bio Sans W04 Regular.woff2') format('woff2'), 
       url('fonts/Bio Sans W04 Regular.woff') format('woff'),  
       url('fonts/Bio Sans W04 Regular.ttf') format('ttf'); 
  font-weight: normal;
  font-style: normal;
}

.contentpg {
  margin: 1em
}

.large-font {
  font-size: 5em; /* Adjust the value as needed */
}

.blueheading {
  color: #2834F8;
}

.ghostbutton {
  color: #FFFFFF;
  border: #FFFFFF;
  background-color: #FFFFFF;
}

.mini-logo-container {
  text-align: center;
  padding-bottom: 20px;
}

/* CSS */
.button-8 {
  background-color: #e1ecf4;
  border-radius: 3px;
  border: 1px solid #7aa7c7;
  box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #0A4C99;
  cursor: pointer;
  display: inline-block;
  font-family: "BioSans", -apple-system,system-ui,"Segoe UI","Liberation Sans",sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15385;
  margin-bottom: 20px;
  outline: none;
  padding: 8px .8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
  width: 200px;
}

.button-8:hover,
.button-8:focus {
  background-color: #b3d3ea;
  color: #2c5777;
}

.button-8:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.button-8:active {
  background-color: #a0c7e4;
  box-shadow: none;
  color: #2c5777;
}

/* CSS */
.button-copy {
  background-color: #e1ecf4;
  border-radius: 3px;
  border: 1px solid #7aa7c7;
  box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #2834F8;
  cursor: pointer;
  display: inline-block;
  font-family: "BioSans", -apple-system,system-ui,"Segoe UI","Liberation Sans",sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15385;
  margin-bottom: 20px;
  outline: none;
  padding: 8px .8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
  width: 34px;
}

.button-copy:hover,
.button-copy:focus {
  background-color: #b3d3ea;
  color: #2c5777;
}

.button-copy:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.button-copy:active {
  background-color: #a0c7e4;
  box-shadow: none;
  color: #2c5777;
}